import { useState } from "react"

const useHeader = () => {
  const [showSiteNav, setShowSiteNav] = useState(false)

  const toggleSiteNav = (e) => {
    e.preventDefault()
    setShowSiteNav((value) => !value)
  }

  return { showSiteNav, toggleSiteNav }
}

export default useHeader
