import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"
import Obfuscate from "react-obfuscate"
import { event } from "lib/gtag"

const FooterLinksListItem = (props) => {
  const handleClickFooterItem = () => {
    event({ action: "Footer", category: "Click", label: props.title })
  }
  return (
    <li
      className={classNames(
        "fs-small-regular text-element-medium-emphasis-light",
        props.className,
      )}>
      {props.title.match(/Contact Us/i) ? (
        <Obfuscate email={props.href} rel="nofollow">
          {props.title}
        </Obfuscate>
      ) : props.isInternal ? (
        <Link href={props.href} prefetch={false}>
          <a onClick={handleClickFooterItem}>{props.title}</a>
        </Link>
      ) : (
        <a
          href={props.href}
          onClick={handleClickFooterItem}
          target="_blank"
          rel="noopener noreferrer">
          {props.title}
        </a>
      )}
    </li>
  )
}

FooterLinksListItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isInternal: PropTypes.bool,
}

export default FooterLinksListItem
