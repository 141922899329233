import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"
import { event } from "lib/gtag"
import ListSection from "components/UI/ListSection"
import ListCell from "components/UI/ListCell"
import Footer from "components/Footer"

import {
  ArticleIcon,
  MoviePlayIcon,
  NobodySurfIcon,
  ShopIcon,
  LinkIcon,
  InstagramIcon,
  YouTubeIcon,
  SpotifyIcon,
  FacebookIcon,
  PinterestIcon,
  TikTokIcon,
} from "assets/icons"

const HeaderSiteNav = (props) => {
  const rowLen = props.links.length
  let icon = <LinkIcon />

  const handleClickSiteNav = (title) => {
    event({ action: "SiteNav", category: "Click", label: title })
  }

  return (
    <div
      className={classNames(
        props.className,
        "bg-base-background-light md:w-100 md:flex-1 text-element-extra-emphasis-light overflow-scroll",
      )}>
      <OutsideClickHandler
        display="contents"
        onOutsideClick={props.toggleSiteNav}
        disabled={!props.showSiteNav}>
        <nav className="flex flex-col justify-between h-full mb-20 md:mb-0">
          <div className="px-4">
            {props.links.map((link, i) => {
              if (link.links) {
                const rowLen = link.links.length
                return (
                  <ListSection title={link.title} key={i}>
                    {link.links.map((link, j) => {
                      if (link.icon == "InstagramIcon") {
                        icon = <InstagramIcon />
                      }
                      if (link.icon == "YouTubeIcon") {
                        icon = <YouTubeIcon />
                      }
                      if (link.icon == "SpotifyIcon") {
                        icon = <SpotifyIcon />
                      }
                      if (link.icon == "FacebookIcon") {
                        icon = <FacebookIcon />
                      }
                      if (link.icon == "PinterestIcon") {
                        icon = <PinterestIcon />
                      }
                      if (link.icon == "TikTokIcon") {
                        icon = <TikTokIcon />
                      }
                      var target = link.isInternal ? "_self" : "_blank"
                      return (
                        <ListCell
                          icon={icon}
                          label={link.title}
                          href={link.to}
                          key={link.id}
                          target={target}
                          onClick={() => handleClickSiteNav(link.title)}
                          bottomDivider={rowLen === j + 1 ? false : true}
                          tabIndex={j}
                        />
                      )
                    })}
                  </ListSection>
                )
              }
              if (link.icon == "MoviePlayIcon") {
                icon = <MoviePlayIcon />
              }
              if (link.icon == "ArticleIcon") {
                icon = <ArticleIcon />
              }
              if (link.icon == "NobodySurfIcon") {
                icon = <NobodySurfIcon />
              }
              if (link.icon == "ShopIcon") {
                icon = <ShopIcon />
              }
              var target = link.isInternal ? "_self" : "_blank"
              return (
                <ListCell
                  icon={icon}
                  label={link.title}
                  href={link.to}
                  key={link.id}
                  onClick={() => handleClickSiteNav(link.title)}
                  target={target}
                  bottomDivider={rowLen === i + 2 ? false : true}
                  tabIndex={i}
                />
              )
            })}
          </div>
          <Footer className="hidden bg-transparent md:block" compact />
        </nav>
      </OutsideClickHandler>
    </div>
  )
}

HeaderSiteNav.propTypes = {
  className: PropTypes.string,
  showSiteNav: PropTypes.bool,
  toggleSiteNav: PropTypes.func,
  links: PropTypes.array,
}

export default HeaderSiteNav
