import PropTypes from "prop-types"

const hexToRGB = (hex) => {
  let r = 0,
    g = 0,
    b = 0

  if (hex.length == 4) {
    r = "0x" + hex[1] + hex[1]
    g = "0x" + hex[2] + hex[2]
    b = "0x" + hex[3] + hex[3]
  } else if (hex.length == 7) {
    r = "0x" + hex[1] + hex[2]
    g = "0x" + hex[3] + hex[4]
    b = "0x" + hex[5] + hex[6]
  }

  return +r + "," + +g + "," + +b
}

const ThemeStyle = (theme) => {
  const elementDefault = "#000"
  const baseDefault = "#FFF"

  const elementRgb = hexToRGB(theme.element || elementDefault)
  const baseRgb = hexToRGB(theme.base || baseDefault)

  return (
    <style jsx global>{`
      :root {
        --color-element-light: ${elementRgb};
        --color-element-dark: 255, 255, 255;
        --color-base-light: ${baseRgb};
        --color-base-dark: 0, 0, 0;
      }
    `}</style>
  )
}

ThemeStyle.propTypes = {
  theme: PropTypes.shape({
    element: PropTypes.string,
    base: PropTypes.string,
  }),
}

export default ThemeStyle
