import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { CrossIcon } from "assets/icons"

const ModalAction = (props) => (
  <div
    className={classNames(
      "relative flex flex-col items-stretch w-full px-4 sm:px-6 sm:py-1",
      props.className,
    )}>
    <div className="flex items-center justify-between border-b border-element-divider-light h-14">
      <h4 className="fs-2x-large-regular">{props.title}</h4>
      <CrossIcon
        className="flex-shrink-0 cursor-pointer w-7 h-7"
        onClick={props.onClose}
      />
    </div>

    <div className="flex flex-col items-stretch py-6">{props.children}</div>
  </div>
)

ModalAction.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default ModalAction
