module.exports = {
  Links: {
    BadgeTopPage: "https://nobodysurf.onelink.me/vriP/c7662b9",
    BadgeAppsPage: "https://nobodysurf.onelink.me/vriP/648b0fc0",
    Indicator: "https://nobodysurf.onelink.me/vriP/5c88391e",
    TextModal: "https://nobodysurf.onelink.me/vriP/cec04eb0",
    TextFooter: "https://nobodysurf.onelink.me/vriP/7f6c48c4",
    TextAboutPage: "https://nobodysurf.onelink.me/vriP/2d1fe65a",
    TextAppsPage: "https://nobodysurf.onelink.me/vriP/364b9828",
    TextMenuPage: "https://nobodysurf.onelink.me/vriP/4349ee71",
    ButtonHeader: "https://nobodysurf.onelink.me/vriP/14d7b696",
    ButtonSave: "https://nobodysurf.onelink.me/vriP/aaa39d00",
    ButtonMenuPage: "https://nobodysurf.onelink.me/vriP/c75f01bb",
    TextBannerPlaylistUser: "https://nobodysurf.onelink.me/vriP/7bd8a3ee",
    ButtonSavePlaylist: "https://nobodysurf.onelink.me/vriP/dee018d3",
    YoutubeChannel: "https://www.youtube.com/c/NobodySurf",
    YoutubeSubscribe:
      "https://www.youtube.com/channel/UCOtHosOqPe9d6vLy-8LfHzQ?sub_confirmation=1",
  },
  PlaylistId: {
    mostLovedVideos2020: 545,
  },
}
