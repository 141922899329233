import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import config from "config"

import FooterLinks from "components/Footer/Links"
import FooterCopyright from "components/Footer/Copyright"

const Footer = (props) => (
  <footer
    className={classNames(
      "px-4 py-10",
      { "md:px-8 md:py-14": !props.compact },
      props.className,
    )}>
    <FooterLinks blocks={config.navigations.footer} compact={props.compact} />
    {!props.compact && <FooterCopyright className="mt-10" />}
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
}

export default Footer
