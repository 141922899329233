import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import config from "config"

import HeaderTopBar from "components/Header/TopBar"
import HeaderSiteNav from "components/Header/SiteNav"

const Header = (props) => (
  <div
    className={classNames(
      props.className,
      "flex flex-col w-full z-50 fixed bg-base-modal-overlay-light",
      `${props.showSiteNav ? "h-full" : "h-auto"}`,
    )}>
    <HeaderTopBar
      showSiteNav={props.showSiteNav}
      toggleSiteNav={props.toggleSiteNav}
    />
    {props.showSiteNav && (
      <HeaderSiteNav
        showSiteNav={props.showSiteNav}
        toggleSiteNav={props.toggleSiteNav}
        links={config.navigations.menuNavigation}
      />
    )}
  </div>
)

Header.propTypes = {
  className: PropTypes.string,
  showSiteNav: PropTypes.bool,
  toggleSiteNav: PropTypes.func,
}

export default Header
