import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"

import ButtonContent from "./Content"

const Button = (props) => {
  let classes = "flex items-center justify-center rounded"

  switch (props.variant) {
    case "outlined":
      switch (props.theme) {
        case "dark":
          classes += " bg-transparent border border-element-extra-emphasis-dark"
          break
        case "light":
        default:
          classes +=
            " bg-transparent border border-element-extra-emphasis-light"
      }
      break
    case "contained":
      switch (props.theme) {
        case "dark":
          classes += " bg-base-background-dark"
          break
        case "light":
        default:
          classes += " bg-base-background-light"
      }
      break
    case "elevated":
      switch (props.theme) {
        case "dark":
          classes += " bg-base-elevated-dark"
          break
        case "light":
        default:
          classes += " bg-base-elevated-light"
      }
      break
    case "blank":
    default:
      break
  }

  switch (props.emphasis) {
    case "disabled":
      classes += " cursor-default "
      switch (props.theme) {
        case "dark":
          classes += " text-element-high-emphasis-dark opacity-disabled-dark"
          break
        case "light":
        default:
          classes += " text-element-high-emphasis-light opacity-disabled-light"
      }
      break
    case "low":
      classes += " cursor-pointer"
      switch (props.theme) {
        case "dark":
          classes += " text-element-low-emphasis-dark"
          break
        case "light":
        default:
          classes += " text-element-low-emphasis-light "
      }
      break
    case "medium":
      classes += " cursor-pointer"
      switch (props.theme) {
        case "dark":
          classes += " text-element-medium-emphasis-dark"
          break
        case "light":
        default:
          classes += " text-element-medium-emphasis-light"
      }
      break
    case "high":
      classes += " cursor-pointer"
      switch (props.theme) {
        case "dark":
          classes += " text-element-high-emphasis-dark"
          break
        case "light":
        default:
          classes += " text-element-high-emphasis-light"
      }
      break
    case "extra":
    default:
      classes += " cursor-pointer"
      switch (props.theme) {
        case "dark":
          classes += " text-element-extra-emphasis-dark"
          break
        case "light":
        default:
          classes += " text-element-extra-emphasis-light"
      }
      break
  }

  switch (props.size) {
    case "large":
      classes += " px-14 py-4 fs-base-interface-bold"
      break
    case "dynamic":
      classes += " px-3 py-3 sm:px-14 sm:py-4 fs-base-interface-bold"
      break
    case "regular":
      classes += " px-3 py-3 fs-base-interface-bold"
      break
    case "small":
    default:
      classes += " px-3 py-2 fs-small-regular"
  }

  if (props.href) {
    return (
      <a
        href={props.href}
        className={classNames(props.className, classes)}
        onClick={props.onClick}
        target={props.target}
        aria-label={props.label}
        rel="noopener">
        <ButtonContent {...props} />
      </a>
    )
  }
  if (props.link) {
    return (
      <Link href={props.link} prefetch={false} passHref>
        <a target={props.target} rel="noopener">
          <button
            className={classNames(props.className, classes)}
            onClick={props.onClick}
            aria-label={props.label}>
            <ButtonContent {...props} />
          </button>
        </a>
      </Link>
    )
  }

  return (
    <button
      className={classNames(props.className, classes)}
      onClick={props.onClick}
      type={props.type}
      aria-label={props.label}>
      <ButtonContent {...props} />
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  iconBefore: PropTypes.node,
  label: PropTypes.string,
  iconAfter: PropTypes.node,
  href: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  variant: PropTypes.string,
  emphasis: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
}

Button.defaultProps = {
  target: "_self",
  theme: "light",
}

export default Button
