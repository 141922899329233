import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const FooterCopyright = (props) => {
  const currYear = new Date().getFullYear()

  return (
    <div
      className={classNames(
        "fs-small-regular text-element-low-emphasis-light",
        props.className,
      )}>
      © {currYear} NobodySurf. All rights reserved.
    </div>
  )
}

FooterCopyright.propTypes = {
  className: PropTypes.string,
}

export default FooterCopyright
