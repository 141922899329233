import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next"
import Divider from "components/UI/Divider"
import { ArrowSmallRightIcon } from "assets/icons"

const ListCellWrapper = (props) => {
  if (props.isInternal) {
    return (
      <Link {...props}>
        <a onClick={props.onClick}>{props.children}</a>
      </Link>
    )
  }
  return <a {...props}>{props.children}</a>
}

const ListCell = (props) => {
  return (
    <ListCellWrapper
      href={props.href}
      onClick={props.onClick}
      target={props.target}
      rel={props.rel}
      tabIndex={props.tabIndex}
      prefetch={false}
      className={classNames(
        "flex items-center relative py-4 fs-base-interface-bold",
        props.href ? "cursor-pointer" : "cursor-default",
        props.className,
      )}>
      <div className="flex items-center flex-1 h-6">
        {props.icon && (
          <div className="w-6 h-6 mr-2 fill-current">{props.icon}</div>
        )}
        <div>{props.label}</div>
      </div>
      <ArrowSmallRightIcon className="w-4 h-4 opacity-25 md:invisible" />
      {props.bottomDivider && <Divider className="absolute bottom-0 w-full" />}
    </ListCellWrapper>
  )
}

ListCell.propTypes = {
  rel: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object,
  target: PropTypes.string,
  bottomDivider: PropTypes.bool,
  tabIndex: PropTypes.number,
}

ListCellWrapper.propTypes = {
  isInternal: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

ListCell.defaultProps = {
  target: "_self",
}

export default ListCell
