import React, { useEffect, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { NobodySurfIcon, CrossWrappedIcon } from "assets/icons"

const Indicator = (props) => {
  const visibleIndicator = "translate-y-0 out-expo"
  const hiddenIndicator = "translate-y-48 in-expo"

  const [toggleIndicator, setToggleIndicator] = useState(hiddenIndicator)

  useEffect(() => {
    if (props.appearMotion) {
      const timer = setTimeout(() => {
        setToggleIndicator(visibleIndicator)
      }, 3000)
      return () => clearTimeout(timer)
    }
    return setToggleIndicator(visibleIndicator)
  }, [])

  return (
    <div
      className={classNames(
        "flex justify-center fixed bottom-0 w-full z-40 safe-bottom mb-6 transform transition duration-300",
        toggleIndicator,
      )}>
      <div
        className={classNames(
          "flex items-center h-12 rounded-full bg-base-surface-light text-element-extra-emphasis-light  backdrop-blur filter invert shadow-indicator",
        )}>
        <a
          href={props.buttonHref}
          className="flex items-center h-full"
          onClick={props.onClick}>
          <NobodySurfIcon className="w-8 h-8 ml-4 mr-3" />
          <span className="fs-base-interface-bold">{props.label}</span>
        </a>
        <div
          className="py-4 pl-3 pr-4"
          onClick={() => {
            setTimeout(props.onClose, 300)
            setToggleIndicator(hiddenIndicator)
          }}
          aria-hidden="true">
          <CrossWrappedIcon className="w-4 h-4 text-element-low-emphasis-light" />
        </div>
      </div>
    </div>
  )
}

Indicator.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  buttonHref: PropTypes.string,
  appearMotion: PropTypes.bool,
}

Indicator.defaultProps = {
  label: "Open NobodySurf App",
}

export default Indicator
