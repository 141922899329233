import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const ListSection = (props) => (
  <div className={classNames(props.className, "mt-6")}>
    <div className="py-2 fs-small-regular">{props.title}</div>
    {props.children}
  </div>
)

ListSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default ListSection
