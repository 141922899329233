import { useRouter } from "next/router"
import urlcat from "urlcat"

const DYNAMIC_URL = "https://nobodysurf.page.link/"

const dynamicLink = (link, props) => {
  const router = useRouter()
  if (router.pathname == "/") {
    return createLinkToTop(link)
  } else if (router.pathname == "/[slug]" && props.video) {
    return createLinkToMovie(link, props.video)
  } else if (router.pathname == "/search") {
    return createLinkToSearch(link, router.asPath)
  } else if (router.pathname == "/tag") {
    return createLinkToTag(link, router.asPath)
  } else if (router.pathname == "/location") {
    return createLinkToTag(link, router.asPath)
  } else if (router.pathname == "/playlist/[hash]") {
    return createLinkToPlayList(link, router.query.hash, props)
  }
  return link
}

function createBaseParams(link, meta) {
  var params = {
    apn: "co.reblue.android.nobodysurf",
    afl: link,
    ibi: "com.reblue.nobodysurf",
    ifl: link,
    ipfl: link,
    ipbi: "com.reblue.nobodysurf",
    isi: 1146730002,
    ofl: "https://nobodysurf.com/apps",
  }
  if (meta.title) {
    params.st = meta.title
  }
  if (meta.description) {
    params.sd = meta.description
  }
  if (meta.image) {
    params.si = urlcat(meta.image, { f: "auto", d: 320 })
  }
  return params
}

function createLinkToTop(link) {
  return urlcat(DYNAMIC_URL, "/", {
    link: "https://nobodysurf.com/",
    ...createBaseParams(link, {}),
  })
}

function createLinkToMovie(link, video) {
  const movieLink = urlcat(video.web_url, { movie: video.movie_id })
  return urlcat(DYNAMIC_URL, "/", {
    link: movieLink,
    ...createBaseParams(link, {}),
  })
}

function createLinkToSearch(link, path) {
  const searchLink = urlcat("https://nobodysurf.com/apps" + path)
  return urlcat(DYNAMIC_URL, {
    link: searchLink,
    ...createBaseParams(link, {}),
  })
}

function createLinkToTag(link, path) {
  const match = path.match(/\/([^/]+)\/?$/)
  if (match !== null && match.length > 1) {
    return createLinkToSearch(link, "/search?tags[]=" + match[1])
  }
  return link
}

function createLinkToPlayList(link, hash, props) {
  const playListLink = urlcat("https://app.nobodysurf.com/playlist/:hash", {
    hash: hash,
  })
  return urlcat(DYNAMIC_URL, {
    link: playListLink,
    ...createBaseParams(link, props),
  })
}

export default dynamicLink
