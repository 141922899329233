import { AirplayIcon } from "./actionIcons/Airplay.svg"
import { ArrowDownAltIcon } from "./actionIcons/Arrow-down-alt.svg"
import { ArrowDownIcon } from "./actionIcons/Arrow-down.svg"
import { ArrowLeftAltIcon } from "./actionIcons/Arrow-left-alt.svg"
import { ArrowLeftIcon } from "./actionIcons/Arrow-left.svg"
import { ArrowRightAltIcon } from "./actionIcons/Arrow-right-alt.svg"
import { ArrowRightIcon } from "./actionIcons/Arrow-right.svg"
import { ArrowSmallRightIcon } from "./actionIcons/Arrow-small-right.svg"
import { ArrowUpIcon } from "./actionIcons/Arrow-up.svg"
import { CastIcon } from "./actionIcons/Cast.svg"
import { CheckIcon } from "./actionIcons/Check.svg"
import { CircleIcon } from "./actionIcons/Circle.svg"
import { ContrastDarkIcon } from "./actionIcons/Contrast-dark.svg"
import { ContrastLightIcon } from "./actionIcons/Contrast-light.svg"
import { ContrastIcon } from "./actionIcons/Contrast.svg"
import { Copy } from "./actionIcons/Copy.svg"
import { CrossWrappedIcon } from "./actionIcons/Cross-wrapped.svg"
import { CrossIcon } from "./actionIcons/Cross.svg"
import { DataIcon } from "./actionIcons/Data.svg"
import { DropDownIcon } from "./actionIcons/Drop-down.svg"
import { HdOnIcon } from "./actionIcons/HD-on.svg"
import { LoaderIcon } from "./actionIcons/Loader.svg"
import { MailIcon } from "./actionIcons/Mail.svg"
import { MoviePauseIcon } from "./actionIcons/Movie-pause.svg"
import { MoviePlayIcon } from "./actionIcons/Movie-play.svg"
import { MovieSkipBackwardIcon } from "./actionIcons/Movie-skip-backward.svg"
import { MovieSkipFowardIcon } from "./actionIcons/Movie-skip-foward.svg"
import { MovieSkipNextIcon } from "./actionIcons/Movie-skip-next.svg"
import { MovieSkipPreviousIcon } from "./actionIcons/Movie-skip-previous.svg"
import { OptionHorizontalIcon } from "./actionIcons/Options-horizontal.svg"
import { OptionsVerticalIcon } from "./actionIcons/Options-vertical.svg"
import { PlusIcon } from "./actionIcons/Plus.svg"
import { ReplayIcon } from "./actionIcons/Replay.svg"
import { SaveIcon } from "./actionIcons/Save.svg"
import { SavedIcon } from "./actionIcons/Saved.svg"
import { ScreenFullIcon } from "./actionIcons/Screen-full.svg"
import { ScreenNormalIcon } from "./actionIcons/Screen-normal.svg"
import { SearchIcon } from "./actionIcons/Search.svg"
import { SettingsIcon } from "./actionIcons/Settings.svg"
import { ShareIcon } from "./actionIcons/Share.svg"
import { ShuffleIcon } from "./actionIcons/Shuffle.svg"
import { SoundOffIcon } from "./actionIcons/Sound-off.svg"
import { SoundOnIcon } from "./actionIcons/Sound-on.svg"
import { SquareOutlineIcon } from "./actionIcons/Square-outline.svg"
import { SquareIcon } from "./actionIcons/Square.svg"
import { StarIcon } from "./actionIcons/Star.svg"
import { TrashIcon } from "./actionIcons/Trash.svg"
import { AdditionalFootageIcon } from "assets/icons/illustrativeIcons/Additional-footage.svg"
import { BoardSizeIcon } from "./illustrativeIcons/Board-size.svg"
import { BoardTypeIcon } from "./illustrativeIcons/Board-type.svg"
import { BrandIcon } from "./illustrativeIcons/Brand.svg"
import { CalendarIcon } from "./illustrativeIcons/Calendar.svg"
import { DirectedByIcon } from "./illustrativeIcons/Directed-by.svg"
import { DroneFootageIcon } from "./illustrativeIcons/Drone-footage.svg"
import { EditIcon } from "./illustrativeIcons/Edit.svg"
import { FilmEditIcon } from "./illustrativeIcons/Film-edit.svg"
import { FilmEndIcon } from "./illustrativeIcons/Film-end.svg"
import { FilmFootageIcon } from "./illustrativeIcons/Film-footage.svg"
import { FilmIcon } from "./illustrativeIcons/Film.svg"
import { FinBonzerIcon } from "./illustrativeIcons/Fin-bonzer.svg"
import { FinLessIcon } from "./illustrativeIcons/Fin-less.svg"
import { FinlessIcon } from "./illustrativeIcons/Finless.svg"
import { FinQuadIcon } from "./illustrativeIcons/Fin-quad.svg"
import { FinSingleIcon } from "./illustrativeIcons/Fin-single.svg"
import { FinTriIcon } from "./illustrativeIcons/Fin-tri.svg"
import { FinTwinIcon } from "./illustrativeIcons/Fin-twin.svg"
import { FinIcon } from "./illustrativeIcons/Fin.svg"
import { FoamIcon } from "./illustrativeIcons/Foam.svg"
import { GenderFemaleIcon } from "./illustrativeIcons/Gender-female.svg"
import { GenderMaleIcon } from "./illustrativeIcons/Gender-male.svg"
import { InformationIcon } from "./illustrativeIcons/Information.svg"
import { LegendsSpecialAltIcon } from "./illustrativeIcons/Legends-special-alt.svg"
import { LegendsSpecialIcon } from "./illustrativeIcons/Legends-special.svg"
import { LibraryIcon } from "./illustrativeIcons/Library.svg"
import { LinkVideoIcon } from "./illustrativeIcons/Link-video.svg"
import { LinkIcon } from "./illustrativeIcons/Link.svg"
import { LocationIcon } from "./illustrativeIcons/Location.svg"
import { MusicIcon } from "./illustrativeIcons/Music.svg"
import { NarratedByIcon } from "./illustrativeIcons/Narrated-by.svg"
import { OndemandMovieIcon } from "./illustrativeIcons/Ondemand-movie.svg"
import { PresentedByIcon } from "./illustrativeIcons/Presented-by.svg"
import { ProducedByIcon } from "./illustrativeIcons/Produced-by.svg"
import { ProfileIcon } from "./illustrativeIcons/Profile.svg"
import { RailIcon } from "./illustrativeIcons/Rail.svg"
import { RuntimeIcon } from "./illustrativeIcons/Runtime.svg"
import { SeriesIcon } from "./illustrativeIcons/Series.svg"
import { ShaperIcon } from "./illustrativeIcons/Shaper.svg"
import { ShopIcon } from "./illustrativeIcons/Shop.svg"
import { SponsoredAltIcon } from "./illustrativeIcons/Sponsored-alt.svg"
import { SponsoredIcon } from "./illustrativeIcons/Sponsored.svg"
import { StanceGoofyIcon } from "./illustrativeIcons/Stance-goofy.svg"
import { StanceRegularIcon } from "./illustrativeIcons/Stance-regular.svg"
import { SupportedIcon } from "./illustrativeIcons/Supported.svg"
import { SurfboardIcon } from "./illustrativeIcons/Surfboard.svg"
import { SurferAltIcon } from "./illustrativeIcons/Surfer-alt.svg"
import { SurferIcon } from "./illustrativeIcons/Surfer.svg"
import { TailIcon } from "./illustrativeIcons/Tail.svg"
import { VolumeIcon } from "./illustrativeIcons/Volume.svg"
import { WaterFootageIcon } from "./illustrativeIcons/Water-footage.svg"
import { WaveBigIcon } from "./illustrativeIcons/Wave-big.svg"
import { WaveChestHeadIcon } from "./illustrativeIcons/Wave-chest-head.svg"
import { WaveHugeIcon } from "./illustrativeIcons/Wave-huge.svg"
import { WaveOverheadIcon } from "./illustrativeIcons/Wave-overhead.svg"
import { WaveSmallIcon } from "./illustrativeIcons/Wave-small.svg"
import { ArticleIcon } from "./illustrativeIcons/Article.svg"
import { ArticleNewIcon } from "./illustrativeIcons/Article-new.svg"
import { AndroidIcon } from "./platformIcons/Android.svg"
import { AppleIcon } from "./platformIcons/Apple.svg"
import { FacebookIcon } from "./platformIcons/Facebook.svg"
import { GoogleIcon } from "./platformIcons/Google.svg"
import { InstagramIcon } from "./platformIcons/Instagram.svg"
import { TwitterIcon } from "./platformIcons/Twitter.svg"
import { TikTokIcon } from "./platformIcons/TikTok.svg"
import { NobodySurfIcon } from "./platformIcons/NobodySurf.svg"
import { YouTubeIcon } from "./platformIcons/Youtube.svg"
import { SpotifyIcon } from "./platformIcons/Spotify.svg"
import { PinterestIcon } from "./platformIcons/Pinterest.svg"
import { VimeoIcon } from "./platformIcons/Vimeo.svg"
import { SnapchatIcon } from "./platformIcons/Snapchat.svg"
import { TeeIcon } from "./illustrativeIcons/Tee.svg"
import { CountryFlag_Default } from "./flagIcons/default.svg"
import { CountryFlag_1F1E6_1F1E8 } from "./flagIcons/U+1F1E6-U+1F1E8.svg"
import { CountryFlag_1F1E6_1F1E9 } from "./flagIcons/U+1F1E6-U+1F1E9.svg"
import { CountryFlag_1F1E6_1F1EA } from "./flagIcons/U+1F1E6-U+1F1EA.svg"
import { CountryFlag_1F1E6_1F1EB } from "./flagIcons/U+1F1E6-U+1F1EB.svg"
import { CountryFlag_1F1E6_1F1EC } from "./flagIcons/U+1F1E6-U+1F1EC.svg"
import { CountryFlag_1F1E6_1F1EE } from "./flagIcons/U+1F1E6-U+1F1EE.svg"
import { CountryFlag_1F1E6_1F1F1 } from "./flagIcons/U+1F1E6-U+1F1F1.svg"
import { CountryFlag_1F1E6_1F1F2 } from "./flagIcons/U+1F1E6-U+1F1F2.svg"
import { CountryFlag_1F1E6_1F1F4 } from "./flagIcons/U+1F1E6-U+1F1F4.svg"
import { CountryFlag_1F1E6_1F1F6 } from "./flagIcons/U+1F1E6-U+1F1F6.svg"
import { CountryFlag_1F1E6_1F1F7 } from "./flagIcons/U+1F1E6-U+1F1F7.svg"
import { CountryFlag_1F1E6_1F1F8 } from "./flagIcons/U+1F1E6-U+1F1F8.svg"
import { CountryFlag_1F1E6_1F1F9 } from "./flagIcons/U+1F1E6-U+1F1F9.svg"
import { CountryFlag_1F1E6_1F1FA } from "./flagIcons/U+1F1E6-U+1F1FA.svg"
import { CountryFlag_1F1E6_1F1FC } from "./flagIcons/U+1F1E6-U+1F1FC.svg"
import { CountryFlag_1F1E6_1F1FD } from "./flagIcons/U+1F1E6-U+1F1FD.svg"
import { CountryFlag_1F1E6_1F1FF } from "./flagIcons/U+1F1E6-U+1F1FF.svg"
import { CountryFlag_1F1E7_1F1E6 } from "./flagIcons/U+1F1E7-U+1F1E6.svg"
import { CountryFlag_1F1E7_1F1E7 } from "./flagIcons/U+1F1E7-U+1F1E7.svg"
import { CountryFlag_1F1E7_1F1E9 } from "./flagIcons/U+1F1E7-U+1F1E9.svg"
import { CountryFlag_1F1E7_1F1EA } from "./flagIcons/U+1F1E7-U+1F1EA.svg"
import { CountryFlag_1F1E7_1F1EB } from "./flagIcons/U+1F1E7-U+1F1EB.svg"
import { CountryFlag_1F1E7_1F1EC } from "./flagIcons/U+1F1E7-U+1F1EC.svg"
import { CountryFlag_1F1E7_1F1ED } from "./flagIcons/U+1F1E7-U+1F1ED.svg"
import { CountryFlag_1F1E7_1F1EE } from "./flagIcons/U+1F1E7-U+1F1EE.svg"
import { CountryFlag_1F1E7_1F1EF } from "./flagIcons/U+1F1E7-U+1F1EF.svg"
import { CountryFlag_1F1E7_1F1F1 } from "./flagIcons/U+1F1E7-U+1F1F1.svg"
import { CountryFlag_1F1E7_1F1F2 } from "./flagIcons/U+1F1E7-U+1F1F2.svg"
import { CountryFlag_1F1E7_1F1F3 } from "./flagIcons/U+1F1E7-U+1F1F3.svg"
import { CountryFlag_1F1E7_1F1F4 } from "./flagIcons/U+1F1E7-U+1F1F4.svg"
import { CountryFlag_1F1E7_1F1F6 } from "./flagIcons/U+1F1E7-U+1F1F6.svg"
import { CountryFlag_1F1E7_1F1F7 } from "./flagIcons/U+1F1E7-U+1F1F7.svg"
import { CountryFlag_1F1E7_1F1F8 } from "./flagIcons/U+1F1E7-U+1F1F8.svg"
import { CountryFlag_1F1E7_1F1F9 } from "./flagIcons/U+1F1E7-U+1F1F9.svg"
import { CountryFlag_1F1E7_1F1FB } from "./flagIcons/U+1F1E7-U+1F1FB.svg"
import { CountryFlag_1F1E7_1F1FC } from "./flagIcons/U+1F1E7-U+1F1FC.svg"
import { CountryFlag_1F1E7_1F1FE } from "./flagIcons/U+1F1E7-U+1F1FE.svg"
import { CountryFlag_1F1E7_1F1FF } from "./flagIcons/U+1F1E7-U+1F1FF.svg"
import { CountryFlag_1F1E8_1F1E6 } from "./flagIcons/U+1F1E8-U+1F1E6.svg"
import { CountryFlag_1F1E8_1F1E8 } from "./flagIcons/U+1F1E8-U+1F1E8.svg"
import { CountryFlag_1F1E8_1F1E9 } from "./flagIcons/U+1F1E8-U+1F1E9.svg"
import { CountryFlag_1F1E8_1F1EB } from "./flagIcons/U+1F1E8-U+1F1EB.svg"
import { CountryFlag_1F1E8_1F1EC } from "./flagIcons/U+1F1E8-U+1F1EC.svg"
import { CountryFlag_1F1E8_1F1ED } from "./flagIcons/U+1F1E8-U+1F1ED.svg"
import { CountryFlag_1F1E8_1F1EE } from "./flagIcons/U+1F1E8-U+1F1EE.svg"
import { CountryFlag_1F1E8_1F1F0 } from "./flagIcons/U+1F1E8-U+1F1F0.svg"
import { CountryFlag_1F1E8_1F1F1 } from "./flagIcons/U+1F1E8-U+1F1F1.svg"
import { CountryFlag_1F1E8_1F1F2 } from "./flagIcons/U+1F1E8-U+1F1F2.svg"
import { CountryFlag_1F1E8_1F1F3 } from "./flagIcons/U+1F1E8-U+1F1F3.svg"
import { CountryFlag_1F1E8_1F1F4 } from "./flagIcons/U+1F1E8-U+1F1F4.svg"
import { CountryFlag_1F1E8_1F1F5 } from "./flagIcons/U+1F1E8-U+1F1F5.svg"
import { CountryFlag_1F1E8_1F1F7 } from "./flagIcons/U+1F1E8-U+1F1F7.svg"
import { CountryFlag_1F1E8_1F1FA } from "./flagIcons/U+1F1E8-U+1F1FA.svg"
import { CountryFlag_1F1E8_1F1FB } from "./flagIcons/U+1F1E8-U+1F1FB.svg"
import { CountryFlag_1F1E8_1F1FC } from "./flagIcons/U+1F1E8-U+1F1FC.svg"
import { CountryFlag_1F1E8_1F1FD } from "./flagIcons/U+1F1E8-U+1F1FD.svg"
import { CountryFlag_1F1E8_1F1FE } from "./flagIcons/U+1F1E8-U+1F1FE.svg"
import { CountryFlag_1F1E8_1F1FF } from "./flagIcons/U+1F1E8-U+1F1FF.svg"
import { CountryFlag_1F1E9_1F1EA } from "./flagIcons/U+1F1E9-U+1F1EA.svg"
import { CountryFlag_1F1E9_1F1EC } from "./flagIcons/U+1F1E9-U+1F1EC.svg"
import { CountryFlag_1F1E9_1F1EF } from "./flagIcons/U+1F1E9-U+1F1EF.svg"
import { CountryFlag_1F1E9_1F1F0 } from "./flagIcons/U+1F1E9-U+1F1F0.svg"
import { CountryFlag_1F1E9_1F1F2 } from "./flagIcons/U+1F1E9-U+1F1F2.svg"
import { CountryFlag_1F1E9_1F1F4 } from "./flagIcons/U+1F1E9-U+1F1F4.svg"
import { CountryFlag_1F1E9_1F1FF } from "./flagIcons/U+1F1E9-U+1F1FF.svg"
import { CountryFlag_1F1EA_1F1E6 } from "./flagIcons/U+1F1EA-U+1F1E6.svg"
import { CountryFlag_1F1EA_1F1E8 } from "./flagIcons/U+1F1EA-U+1F1E8.svg"
import { CountryFlag_1F1EA_1F1EA } from "./flagIcons/U+1F1EA-U+1F1EA.svg"
import { CountryFlag_1F1EA_1F1EC } from "./flagIcons/U+1F1EA-U+1F1EC.svg"
import { CountryFlag_1F1EA_1F1ED } from "./flagIcons/U+1F1EA-U+1F1ED.svg"
import { CountryFlag_1F1EA_1F1F7 } from "./flagIcons/U+1F1EA-U+1F1F7.svg"
import { CountryFlag_1F1EA_1F1F8 } from "./flagIcons/U+1F1EA-U+1F1F8.svg"
import { CountryFlag_1F1EA_1F1F9 } from "./flagIcons/U+1F1EA-U+1F1F9.svg"
import { CountryFlag_1F1EA_1F1FA } from "./flagIcons/U+1F1EA-U+1F1FA.svg"
import { CountryFlag_1F1EB_1F1EE } from "./flagIcons/U+1F1EB-U+1F1EE.svg"
import { CountryFlag_1F1EB_1F1EF } from "./flagIcons/U+1F1EB-U+1F1EF.svg"
import { CountryFlag_1F1EB_1F1F0 } from "./flagIcons/U+1F1EB-U+1F1F0.svg"
import { CountryFlag_1F1EB_1F1F2 } from "./flagIcons/U+1F1EB-U+1F1F2.svg"
import { CountryFlag_1F1EB_1F1F4 } from "./flagIcons/U+1F1EB-U+1F1F4.svg"
import { CountryFlag_1F1EB_1F1F7 } from "./flagIcons/U+1F1EB-U+1F1F7.svg"
import { CountryFlag_1F1EC_1F1E6 } from "./flagIcons/U+1F1EC-U+1F1E6.svg"
import { CountryFlag_1F1EC_1F1E7 } from "./flagIcons/U+1F1EC-U+1F1E7.svg"
import { CountryFlag_1F1EC_1F1E9 } from "./flagIcons/U+1F1EC-U+1F1E9.svg"
import { CountryFlag_1F1EC_1F1EA } from "./flagIcons/U+1F1EC-U+1F1EA.svg"
import { CountryFlag_1F1EC_1F1EB } from "./flagIcons/U+1F1EC-U+1F1EB.svg"
import { CountryFlag_1F1EC_1F1EC } from "./flagIcons/U+1F1EC-U+1F1EC.svg"
import { CountryFlag_1F1EC_1F1ED } from "./flagIcons/U+1F1EC-U+1F1ED.svg"
import { CountryFlag_1F1EC_1F1EE } from "./flagIcons/U+1F1EC-U+1F1EE.svg"
import { CountryFlag_1F1EC_1F1F1 } from "./flagIcons/U+1F1EC-U+1F1F1.svg"
import { CountryFlag_1F1EC_1F1F2 } from "./flagIcons/U+1F1EC-U+1F1F2.svg"
import { CountryFlag_1F1EC_1F1F3 } from "./flagIcons/U+1F1EC-U+1F1F3.svg"
import { CountryFlag_1F1EC_1F1F5 } from "./flagIcons/U+1F1EC-U+1F1F5.svg"
import { CountryFlag_1F1EC_1F1F6 } from "./flagIcons/U+1F1EC-U+1F1F6.svg"
import { CountryFlag_1F1EC_1F1F7 } from "./flagIcons/U+1F1EC-U+1F1F7.svg"
import { CountryFlag_1F1EC_1F1F8 } from "./flagIcons/U+1F1EC-U+1F1F8.svg"
import { CountryFlag_1F1EC_1F1F9 } from "./flagIcons/U+1F1EC-U+1F1F9.svg"
import { CountryFlag_1F1EC_1F1FA } from "./flagIcons/U+1F1EC-U+1F1FA.svg"
import { CountryFlag_1F1EC_1F1FC } from "./flagIcons/U+1F1EC-U+1F1FC.svg"
import { CountryFlag_1F1EC_1F1FE } from "./flagIcons/U+1F1EC-U+1F1FE.svg"
import { CountryFlag_1F1ED_1F1F0 } from "./flagIcons/U+1F1ED-U+1F1F0.svg"
import { CountryFlag_1F1ED_1F1F2 } from "./flagIcons/U+1F1ED-U+1F1F2.svg"
import { CountryFlag_1F1ED_1F1F3 } from "./flagIcons/U+1F1ED-U+1F1F3.svg"
import { CountryFlag_1F1ED_1F1F7 } from "./flagIcons/U+1F1ED-U+1F1F7.svg"
import { CountryFlag_1F1ED_1F1F9 } from "./flagIcons/U+1F1ED-U+1F1F9.svg"
import { CountryFlag_1F1ED_1F1FA } from "./flagIcons/U+1F1ED-U+1F1FA.svg"
import { CountryFlag_1F1EE_1F1E8 } from "./flagIcons/U+1F1EE-U+1F1E8.svg"
import { CountryFlag_1F1EE_1F1E9 } from "./flagIcons/U+1F1EE-U+1F1E9.svg"
import { CountryFlag_1F1EE_1F1EA } from "./flagIcons/U+1F1EE-U+1F1EA.svg"
import { CountryFlag_1F1EE_1F1F1 } from "./flagIcons/U+1F1EE-U+1F1F1.svg"
import { CountryFlag_1F1EE_1F1F2 } from "./flagIcons/U+1F1EE-U+1F1F2.svg"
import { CountryFlag_1F1EE_1F1F3 } from "./flagIcons/U+1F1EE-U+1F1F3.svg"
import { CountryFlag_1F1EE_1F1F4 } from "./flagIcons/U+1F1EE-U+1F1F4.svg"
import { CountryFlag_1F1EE_1F1F6 } from "./flagIcons/U+1F1EE-U+1F1F6.svg"
import { CountryFlag_1F1EE_1F1F7 } from "./flagIcons/U+1F1EE-U+1F1F7.svg"
import { CountryFlag_1F1EE_1F1F8 } from "./flagIcons/U+1F1EE-U+1F1F8.svg"
import { CountryFlag_1F1EE_1F1F9 } from "./flagIcons/U+1F1EE-U+1F1F9.svg"
import { CountryFlag_1F1EF_1F1EA } from "./flagIcons/U+1F1EF-U+1F1EA.svg"
import { CountryFlag_1F1EF_1F1F2 } from "./flagIcons/U+1F1EF-U+1F1F2.svg"
import { CountryFlag_1F1EF_1F1F4 } from "./flagIcons/U+1F1EF-U+1F1F4.svg"
import { CountryFlag_1F1EF_1F1F5 } from "./flagIcons/U+1F1EF-U+1F1F5.svg"
import { CountryFlag_1F1F0_1F1EA } from "./flagIcons/U+1F1F0-U+1F1EA.svg"
import { CountryFlag_1F1F0_1F1EC } from "./flagIcons/U+1F1F0-U+1F1EC.svg"
import { CountryFlag_1F1F0_1F1ED } from "./flagIcons/U+1F1F0-U+1F1ED.svg"
import { CountryFlag_1F1F0_1F1EE } from "./flagIcons/U+1F1F0-U+1F1EE.svg"
import { CountryFlag_1F1F0_1F1F2 } from "./flagIcons/U+1F1F0-U+1F1F2.svg"
import { CountryFlag_1F1F0_1F1F3 } from "./flagIcons/U+1F1F0-U+1F1F3.svg"
import { CountryFlag_1F1F0_1F1F5 } from "./flagIcons/U+1F1F0-U+1F1F5.svg"
import { CountryFlag_1F1F0_1F1F7 } from "./flagIcons/U+1F1F0-U+1F1F7.svg"
import { CountryFlag_1F1F0_1F1FC } from "./flagIcons/U+1F1F0-U+1F1FC.svg"
import { CountryFlag_1F1F0_1F1FE } from "./flagIcons/U+1F1F0-U+1F1FE.svg"
import { CountryFlag_1F1F0_1F1FF } from "./flagIcons/U+1F1F0-U+1F1FF.svg"
import { CountryFlag_1F1F1_1F1E6 } from "./flagIcons/U+1F1F1-U+1F1E6.svg"
import { CountryFlag_1F1F1_1F1E7 } from "./flagIcons/U+1F1F1-U+1F1E7.svg"
import { CountryFlag_1F1F1_1F1E8 } from "./flagIcons/U+1F1F1-U+1F1E8.svg"
import { CountryFlag_1F1F1_1F1EE } from "./flagIcons/U+1F1F1-U+1F1EE.svg"
import { CountryFlag_1F1F1_1F1F0 } from "./flagIcons/U+1F1F1-U+1F1F0.svg"
import { CountryFlag_1F1F1_1F1F7 } from "./flagIcons/U+1F1F1-U+1F1F7.svg"
import { CountryFlag_1F1F1_1F1F8 } from "./flagIcons/U+1F1F1-U+1F1F8.svg"
import { CountryFlag_1F1F1_1F1F9 } from "./flagIcons/U+1F1F1-U+1F1F9.svg"
import { CountryFlag_1F1F1_1F1FA } from "./flagIcons/U+1F1F1-U+1F1FA.svg"
import { CountryFlag_1F1F1_1F1FB } from "./flagIcons/U+1F1F1-U+1F1FB.svg"
import { CountryFlag_1F1F1_1F1FE } from "./flagIcons/U+1F1F1-U+1F1FE.svg"
import { CountryFlag_1F1F2_1F1E6 } from "./flagIcons/U+1F1F2-U+1F1E6.svg"
import { CountryFlag_1F1F2_1F1E8 } from "./flagIcons/U+1F1F2-U+1F1E8.svg"
import { CountryFlag_1F1F2_1F1E9 } from "./flagIcons/U+1F1F2-U+1F1E9.svg"
import { CountryFlag_1F1F2_1F1EA } from "./flagIcons/U+1F1F2-U+1F1EA.svg"
import { CountryFlag_1F1F2_1F1EB } from "./flagIcons/U+1F1F2-U+1F1EB.svg"
import { CountryFlag_1F1F2_1F1EC } from "./flagIcons/U+1F1F2-U+1F1EC.svg"
import { CountryFlag_1F1F2_1F1ED } from "./flagIcons/U+1F1F2-U+1F1ED.svg"
import { CountryFlag_1F1F2_1F1F0 } from "./flagIcons/U+1F1F2-U+1F1F0.svg"
import { CountryFlag_1F1F2_1F1F1 } from "./flagIcons/U+1F1F2-U+1F1F1.svg"
import { CountryFlag_1F1F2_1F1F2 } from "./flagIcons/U+1F1F2-U+1F1F2.svg"
import { CountryFlag_1F1F2_1F1F3 } from "./flagIcons/U+1F1F2-U+1F1F3.svg"
import { CountryFlag_1F1F2_1F1F4 } from "./flagIcons/U+1F1F2-U+1F1F4.svg"
import { CountryFlag_1F1F2_1F1F5 } from "./flagIcons/U+1F1F2-U+1F1F5.svg"
import { CountryFlag_1F1F2_1F1F6 } from "./flagIcons/U+1F1F2-U+1F1F6.svg"
import { CountryFlag_1F1F2_1F1F7 } from "./flagIcons/U+1F1F2-U+1F1F7.svg"
import { CountryFlag_1F1F2_1F1F8 } from "./flagIcons/U+1F1F2-U+1F1F8.svg"
import { CountryFlag_1F1F2_1F1F9 } from "./flagIcons/U+1F1F2-U+1F1F9.svg"
import { CountryFlag_1F1F2_1F1FA } from "./flagIcons/U+1F1F2-U+1F1FA.svg"
import { CountryFlag_1F1F2_1F1FB } from "./flagIcons/U+1F1F2-U+1F1FB.svg"
import { CountryFlag_1F1F2_1F1FC } from "./flagIcons/U+1F1F2-U+1F1FC.svg"
import { CountryFlag_1F1F2_1F1FD } from "./flagIcons/U+1F1F2-U+1F1FD.svg"
import { CountryFlag_1F1F2_1F1FE } from "./flagIcons/U+1F1F2-U+1F1FE.svg"
import { CountryFlag_1F1F2_1F1FF } from "./flagIcons/U+1F1F2-U+1F1FF.svg"
import { CountryFlag_1F1F3_1F1E6 } from "./flagIcons/U+1F1F3-U+1F1E6.svg"
import { CountryFlag_1F1F3_1F1E8 } from "./flagIcons/U+1F1F3-U+1F1E8.svg"
import { CountryFlag_1F1F3_1F1EA } from "./flagIcons/U+1F1F3-U+1F1EA.svg"
import { CountryFlag_1F1F3_1F1EB } from "./flagIcons/U+1F1F3-U+1F1EB.svg"
import { CountryFlag_1F1F3_1F1EC } from "./flagIcons/U+1F1F3-U+1F1EC.svg"
import { CountryFlag_1F1F3_1F1EE } from "./flagIcons/U+1F1F3-U+1F1EE.svg"
import { CountryFlag_1F1F3_1F1F1 } from "./flagIcons/U+1F1F3-U+1F1F1.svg"
import { CountryFlag_1F1F3_1F1F4 } from "./flagIcons/U+1F1F3-U+1F1F4.svg"
import { CountryFlag_1F1F3_1F1F5 } from "./flagIcons/U+1F1F3-U+1F1F5.svg"
import { CountryFlag_1F1F3_1F1F7 } from "./flagIcons/U+1F1F3-U+1F1F7.svg"
import { CountryFlag_1F1F3_1F1FA } from "./flagIcons/U+1F1F3-U+1F1FA.svg"
import { CountryFlag_1F1F3_1F1FF } from "./flagIcons/U+1F1F3-U+1F1FF.svg"
import { CountryFlag_1F1F4_1F1F2 } from "./flagIcons/U+1F1F4-U+1F1F2.svg"
import { CountryFlag_1F1F5_1F1E6 } from "./flagIcons/U+1F1F5-U+1F1E6.svg"
import { CountryFlag_1F1F5_1F1EA } from "./flagIcons/U+1F1F5-U+1F1EA.svg"
import { CountryFlag_1F1F5_1F1EB } from "./flagIcons/U+1F1F5-U+1F1EB.svg"
import { CountryFlag_1F1F5_1F1EC } from "./flagIcons/U+1F1F5-U+1F1EC.svg"
import { CountryFlag_1F1F5_1F1ED } from "./flagIcons/U+1F1F5-U+1F1ED.svg"
import { CountryFlag_1F1F5_1F1F0 } from "./flagIcons/U+1F1F5-U+1F1F0.svg"
import { CountryFlag_1F1F5_1F1F1 } from "./flagIcons/U+1F1F5-U+1F1F1.svg"
import { CountryFlag_1F1F5_1F1F2 } from "./flagIcons/U+1F1F5-U+1F1F2.svg"
import { CountryFlag_1F1F5_1F1F3 } from "./flagIcons/U+1F1F5-U+1F1F3.svg"
import { CountryFlag_1F1F5_1F1F7 } from "./flagIcons/U+1F1F5-U+1F1F7.svg"
import { CountryFlag_1F1F5_1F1F8 } from "./flagIcons/U+1F1F5-U+1F1F8.svg"
import { CountryFlag_1F1F5_1F1F9 } from "./flagIcons/U+1F1F5-U+1F1F9.svg"
import { CountryFlag_1F1F5_1F1FC } from "./flagIcons/U+1F1F5-U+1F1FC.svg"
import { CountryFlag_1F1F5_1F1FE } from "./flagIcons/U+1F1F5-U+1F1FE.svg"
import { CountryFlag_1F1F6_1F1E6 } from "./flagIcons/U+1F1F6-U+1F1E6.svg"
import { CountryFlag_1F1F7_1F1EA } from "./flagIcons/U+1F1F7-U+1F1EA.svg"
import { CountryFlag_1F1F7_1F1F4 } from "./flagIcons/U+1F1F7-U+1F1F4.svg"
import { CountryFlag_1F1F7_1F1F8 } from "./flagIcons/U+1F1F7-U+1F1F8.svg"
import { CountryFlag_1F1F7_1F1FA } from "./flagIcons/U+1F1F7-U+1F1FA.svg"
import { CountryFlag_1F1F7_1F1FC } from "./flagIcons/U+1F1F7-U+1F1FC.svg"
import { CountryFlag_1F1F8_1F1E6 } from "./flagIcons/U+1F1F8-U+1F1E6.svg"
import { CountryFlag_1F1F8_1F1E7 } from "./flagIcons/U+1F1F8-U+1F1E7.svg"
import { CountryFlag_1F1F8_1F1E8 } from "./flagIcons/U+1F1F8-U+1F1E8.svg"
import { CountryFlag_1F1F8_1F1E9 } from "./flagIcons/U+1F1F8-U+1F1E9.svg"
import { CountryFlag_1F1F8_1F1EA } from "./flagIcons/U+1F1F8-U+1F1EA.svg"
import { CountryFlag_1F1F8_1F1EC } from "./flagIcons/U+1F1F8-U+1F1EC.svg"
import { CountryFlag_1F1F8_1F1ED } from "./flagIcons/U+1F1F8-U+1F1ED.svg"
import { CountryFlag_1F1F8_1F1EE } from "./flagIcons/U+1F1F8-U+1F1EE.svg"
import { CountryFlag_1F1F8_1F1EF } from "./flagIcons/U+1F1F8-U+1F1EF.svg"
import { CountryFlag_1F1F8_1F1F0 } from "./flagIcons/U+1F1F8-U+1F1F0.svg"
import { CountryFlag_1F1F8_1F1F1 } from "./flagIcons/U+1F1F8-U+1F1F1.svg"
import { CountryFlag_1F1F8_1F1F2 } from "./flagIcons/U+1F1F8-U+1F1F2.svg"
import { CountryFlag_1F1F8_1F1F3 } from "./flagIcons/U+1F1F8-U+1F1F3.svg"
import { CountryFlag_1F1F8_1F1F4 } from "./flagIcons/U+1F1F8-U+1F1F4.svg"
import { CountryFlag_1F1F8_1F1F7 } from "./flagIcons/U+1F1F8-U+1F1F7.svg"
import { CountryFlag_1F1F8_1F1F8 } from "./flagIcons/U+1F1F8-U+1F1F8.svg"
import { CountryFlag_1F1F8_1F1F9 } from "./flagIcons/U+1F1F8-U+1F1F9.svg"
import { CountryFlag_1F1F8_1F1FB } from "./flagIcons/U+1F1F8-U+1F1FB.svg"
import { CountryFlag_1F1F8_1F1FD } from "./flagIcons/U+1F1F8-U+1F1FD.svg"
import { CountryFlag_1F1F8_1F1FE } from "./flagIcons/U+1F1F8-U+1F1FE.svg"
import { CountryFlag_1F1F8_1F1FF } from "./flagIcons/U+1F1F8-U+1F1FF.svg"
import { CountryFlag_1F1F9_1F1E6 } from "./flagIcons/U+1F1F9-U+1F1E6.svg"
import { CountryFlag_1F1F9_1F1E8 } from "./flagIcons/U+1F1F9-U+1F1E8.svg"
import { CountryFlag_1F1F9_1F1E9 } from "./flagIcons/U+1F1F9-U+1F1E9.svg"
import { CountryFlag_1F1F9_1F1EB } from "./flagIcons/U+1F1F9-U+1F1EB.svg"
import { CountryFlag_1F1F9_1F1EC } from "./flagIcons/U+1F1F9-U+1F1EC.svg"
import { CountryFlag_1F1F9_1F1ED } from "./flagIcons/U+1F1F9-U+1F1ED.svg"
import { CountryFlag_1F1F9_1F1EF } from "./flagIcons/U+1F1F9-U+1F1EF.svg"
import { CountryFlag_1F1F9_1F1F0 } from "./flagIcons/U+1F1F9-U+1F1F0.svg"
import { CountryFlag_1F1F9_1F1F1 } from "./flagIcons/U+1F1F9-U+1F1F1.svg"
import { CountryFlag_1F1F9_1F1F2 } from "./flagIcons/U+1F1F9-U+1F1F2.svg"
import { CountryFlag_1F1F9_1F1F3 } from "./flagIcons/U+1F1F9-U+1F1F3.svg"
import { CountryFlag_1F1F9_1F1F4 } from "./flagIcons/U+1F1F9-U+1F1F4.svg"
import { CountryFlag_1F1F9_1F1F7 } from "./flagIcons/U+1F1F9-U+1F1F7.svg"
import { CountryFlag_1F1F9_1F1F9 } from "./flagIcons/U+1F1F9-U+1F1F9.svg"
import { CountryFlag_1F1F9_1F1FB } from "./flagIcons/U+1F1F9-U+1F1FB.svg"
import { CountryFlag_1F1F9_1F1FC } from "./flagIcons/U+1F1F9-U+1F1FC.svg"
import { CountryFlag_1F1F9_1F1FF } from "./flagIcons/U+1F1F9-U+1F1FF.svg"
import { CountryFlag_1F1FA_1F1E6 } from "./flagIcons/U+1F1FA-U+1F1E6.svg"
import { CountryFlag_1F1FA_1F1EC } from "./flagIcons/U+1F1FA-U+1F1EC.svg"
import { CountryFlag_1F1FA_1F1F3 } from "./flagIcons/U+1F1FA-U+1F1F3.svg"
import { CountryFlag_1F1FA_1F1F8 } from "./flagIcons/U+1F1FA-U+1F1F8.svg"
import { CountryFlag_1F1FA_1F1FE } from "./flagIcons/U+1F1FA-U+1F1FE.svg"
import { CountryFlag_1F1FA_1F1FF } from "./flagIcons/U+1F1FA-U+1F1FF.svg"
import { CountryFlag_1F1FB_1F1E6 } from "./flagIcons/U+1F1FB-U+1F1E6.svg"
import { CountryFlag_1F1FB_1F1E8 } from "./flagIcons/U+1F1FB-U+1F1E8.svg"
import { CountryFlag_1F1FB_1F1EA } from "./flagIcons/U+1F1FB-U+1F1EA.svg"
import { CountryFlag_1F1FB_1F1EC } from "./flagIcons/U+1F1FB-U+1F1EC.svg"
import { CountryFlag_1F1FB_1F1EE } from "./flagIcons/U+1F1FB-U+1F1EE.svg"
import { CountryFlag_1F1FB_1F1F3 } from "./flagIcons/U+1F1FB-U+1F1F3.svg"
import { CountryFlag_1F1FB_1F1FA } from "./flagIcons/U+1F1FB-U+1F1FA.svg"
import { CountryFlag_1F1FC_1F1EB } from "./flagIcons/U+1F1FC-U+1F1EB.svg"
import { CountryFlag_1F1FC_1F1F8 } from "./flagIcons/U+1F1FC-U+1F1F8.svg"
import { CountryFlag_1F1FD_1F1F0 } from "./flagIcons/U+1F1FD-U+1F1F0.svg"
import { CountryFlag_1F1FE_1F1EA } from "./flagIcons/U+1F1FE-U+1F1EA.svg"
import { CountryFlag_1F1FE_1F1F9 } from "./flagIcons/U+1F1FE-U+1F1F9.svg"
import { CountryFlag_1F1FF_1F1E6 } from "./flagIcons/U+1F1FF-U+1F1E6.svg"
import { CountryFlag_1F1FF_1F1F2 } from "./flagIcons/U+1F1FF-U+1F1F2.svg"
import { CountryFlag_1F1FF_1F1FC } from "./flagIcons/U+1F1FF-U+1F1FC.svg"
import { CountryFlag_1F3F3_FE0F_200D_1F308 } from "./flagIcons/U+1F3F3-U+FE0F-U+200D-U+1F308.svg"
import { CountryFlag_1F3F4_200D_2620_FE0F } from "./flagIcons/U+1F3F4-U+200D-U+2620-U+FE0F.svg"
import { CountryFlag_1F3F4_E0067_E0062_E0065_E006E_E0067_E007F } from "./flagIcons/U+1F3F4-U+E0067-U+E0062-U+E0065-U+E006E-U+E0067-U+E007F.svg"
import { CountryFlag_1F3F4_E0067_E0062_E0073_E0063_E0074_E007F } from "./flagIcons/U+1F3F4-U+E0067-U+E0062-U+E0073-U+E0063-U+E0074-U+E007F.svg"
import { CountryFlag_1F3F4_E0067_E0062_E0077_E006C_E0073_E007F } from "./flagIcons/U+1F3F4-U+E0067-U+E0062-U+E0077-U+E006C-U+E0073-U+E007F.svg"

const CountryFlagMap = {
  "default": CountryFlag_Default,
  "U+1F1E6-U+1F1E8": CountryFlag_1F1E6_1F1E8,
  "U+1F1E6-U+1F1E9": CountryFlag_1F1E6_1F1E9,
  "U+1F1E6-U+1F1EA": CountryFlag_1F1E6_1F1EA,
  "U+1F1E6-U+1F1EB": CountryFlag_1F1E6_1F1EB,
  "U+1F1E6-U+1F1EC": CountryFlag_1F1E6_1F1EC,
  "U+1F1E6-U+1F1EE": CountryFlag_1F1E6_1F1EE,
  "U+1F1E6-U+1F1F1": CountryFlag_1F1E6_1F1F1,
  "U+1F1E6-U+1F1F2": CountryFlag_1F1E6_1F1F2,
  "U+1F1E6-U+1F1F4": CountryFlag_1F1E6_1F1F4,
  "U+1F1E6-U+1F1F6": CountryFlag_1F1E6_1F1F6,
  "U+1F1E6-U+1F1F7": CountryFlag_1F1E6_1F1F7,
  "U+1F1E6-U+1F1F8": CountryFlag_1F1E6_1F1F8,
  "U+1F1E6-U+1F1F9": CountryFlag_1F1E6_1F1F9,
  "U+1F1E6-U+1F1FA": CountryFlag_1F1E6_1F1FA,
  "U+1F1E6-U+1F1FC": CountryFlag_1F1E6_1F1FC,
  "U+1F1E6-U+1F1FD": CountryFlag_1F1E6_1F1FD,
  "U+1F1E6-U+1F1FF": CountryFlag_1F1E6_1F1FF,
  "U+1F1E7-U+1F1E6": CountryFlag_1F1E7_1F1E6,
  "U+1F1E7-U+1F1E7": CountryFlag_1F1E7_1F1E7,
  "U+1F1E7-U+1F1E9": CountryFlag_1F1E7_1F1E9,
  "U+1F1E7-U+1F1EA": CountryFlag_1F1E7_1F1EA,
  "U+1F1E7-U+1F1EB": CountryFlag_1F1E7_1F1EB,
  "U+1F1E7-U+1F1EC": CountryFlag_1F1E7_1F1EC,
  "U+1F1E7-U+1F1ED": CountryFlag_1F1E7_1F1ED,
  "U+1F1E7-U+1F1EE": CountryFlag_1F1E7_1F1EE,
  "U+1F1E7-U+1F1EF": CountryFlag_1F1E7_1F1EF,
  "U+1F1E7-U+1F1F1": CountryFlag_1F1E7_1F1F1,
  "U+1F1E7-U+1F1F2": CountryFlag_1F1E7_1F1F2,
  "U+1F1E7-U+1F1F3": CountryFlag_1F1E7_1F1F3,
  "U+1F1E7-U+1F1F4": CountryFlag_1F1E7_1F1F4,
  "U+1F1E7-U+1F1F6": CountryFlag_1F1E7_1F1F6,
  "U+1F1E7-U+1F1F7": CountryFlag_1F1E7_1F1F7,
  "U+1F1E7-U+1F1F8": CountryFlag_1F1E7_1F1F8,
  "U+1F1E7-U+1F1F9": CountryFlag_1F1E7_1F1F9,
  "U+1F1E7-U+1F1FB": CountryFlag_1F1E7_1F1FB,
  "U+1F1E7-U+1F1FC": CountryFlag_1F1E7_1F1FC,
  "U+1F1E7-U+1F1FE": CountryFlag_1F1E7_1F1FE,
  "U+1F1E7-U+1F1FF": CountryFlag_1F1E7_1F1FF,
  "U+1F1E8-U+1F1E6": CountryFlag_1F1E8_1F1E6,
  "U+1F1E8-U+1F1E8": CountryFlag_1F1E8_1F1E8,
  "U+1F1E8-U+1F1E9": CountryFlag_1F1E8_1F1E9,
  "U+1F1E8-U+1F1EB": CountryFlag_1F1E8_1F1EB,
  "U+1F1E8-U+1F1EC": CountryFlag_1F1E8_1F1EC,
  "U+1F1E8-U+1F1ED": CountryFlag_1F1E8_1F1ED,
  "U+1F1E8-U+1F1EE": CountryFlag_1F1E8_1F1EE,
  "U+1F1E8-U+1F1F0": CountryFlag_1F1E8_1F1F0,
  "U+1F1E8-U+1F1F1": CountryFlag_1F1E8_1F1F1,
  "U+1F1E8-U+1F1F2": CountryFlag_1F1E8_1F1F2,
  "U+1F1E8-U+1F1F3": CountryFlag_1F1E8_1F1F3,
  "U+1F1E8-U+1F1F4": CountryFlag_1F1E8_1F1F4,
  "U+1F1E8-U+1F1F5": CountryFlag_1F1E8_1F1F5,
  "U+1F1E8-U+1F1F7": CountryFlag_1F1E8_1F1F7,
  "U+1F1E8-U+1F1FA": CountryFlag_1F1E8_1F1FA,
  "U+1F1E8-U+1F1FB": CountryFlag_1F1E8_1F1FB,
  "U+1F1E8-U+1F1FC": CountryFlag_1F1E8_1F1FC,
  "U+1F1E8-U+1F1FD": CountryFlag_1F1E8_1F1FD,
  "U+1F1E8-U+1F1FE": CountryFlag_1F1E8_1F1FE,
  "U+1F1E8-U+1F1FF": CountryFlag_1F1E8_1F1FF,
  "U+1F1E9-U+1F1EA": CountryFlag_1F1E9_1F1EA,
  "U+1F1E9-U+1F1EC": CountryFlag_1F1E9_1F1EC,
  "U+1F1E9-U+1F1EF": CountryFlag_1F1E9_1F1EF,
  "U+1F1E9-U+1F1F0": CountryFlag_1F1E9_1F1F0,
  "U+1F1E9-U+1F1F2": CountryFlag_1F1E9_1F1F2,
  "U+1F1E9-U+1F1F4": CountryFlag_1F1E9_1F1F4,
  "U+1F1E9-U+1F1FF": CountryFlag_1F1E9_1F1FF,
  "U+1F1EA-U+1F1E6": CountryFlag_1F1EA_1F1E6,
  "U+1F1EA-U+1F1E8": CountryFlag_1F1EA_1F1E8,
  "U+1F1EA-U+1F1EA": CountryFlag_1F1EA_1F1EA,
  "U+1F1EA-U+1F1EC": CountryFlag_1F1EA_1F1EC,
  "U+1F1EA-U+1F1ED": CountryFlag_1F1EA_1F1ED,
  "U+1F1EA-U+1F1F7": CountryFlag_1F1EA_1F1F7,
  "U+1F1EA-U+1F1F8": CountryFlag_1F1EA_1F1F8,
  "U+1F1EA-U+1F1F9": CountryFlag_1F1EA_1F1F9,
  "U+1F1EA-U+1F1FA": CountryFlag_1F1EA_1F1FA,
  "U+1F1EB-U+1F1EE": CountryFlag_1F1EB_1F1EE,
  "U+1F1EB-U+1F1EF": CountryFlag_1F1EB_1F1EF,
  "U+1F1EB-U+1F1F0": CountryFlag_1F1EB_1F1F0,
  "U+1F1EB-U+1F1F2": CountryFlag_1F1EB_1F1F2,
  "U+1F1EB-U+1F1F4": CountryFlag_1F1EB_1F1F4,
  "U+1F1EB-U+1F1F7": CountryFlag_1F1EB_1F1F7,
  "U+1F1EC-U+1F1E6": CountryFlag_1F1EC_1F1E6,
  "U+1F1EC-U+1F1E7": CountryFlag_1F1EC_1F1E7,
  "U+1F1EC-U+1F1E9": CountryFlag_1F1EC_1F1E9,
  "U+1F1EC-U+1F1EA": CountryFlag_1F1EC_1F1EA,
  "U+1F1EC-U+1F1EB": CountryFlag_1F1EC_1F1EB,
  "U+1F1EC-U+1F1EC": CountryFlag_1F1EC_1F1EC,
  "U+1F1EC-U+1F1ED": CountryFlag_1F1EC_1F1ED,
  "U+1F1EC-U+1F1EE": CountryFlag_1F1EC_1F1EE,
  "U+1F1EC-U+1F1F1": CountryFlag_1F1EC_1F1F1,
  "U+1F1EC-U+1F1F2": CountryFlag_1F1EC_1F1F2,
  "U+1F1EC-U+1F1F3": CountryFlag_1F1EC_1F1F3,
  "U+1F1EC-U+1F1F5": CountryFlag_1F1EC_1F1F5,
  "U+1F1EC-U+1F1F6": CountryFlag_1F1EC_1F1F6,
  "U+1F1EC-U+1F1F7": CountryFlag_1F1EC_1F1F7,
  "U+1F1EC-U+1F1F8": CountryFlag_1F1EC_1F1F8,
  "U+1F1EC-U+1F1F9": CountryFlag_1F1EC_1F1F9,
  "U+1F1EC-U+1F1FA": CountryFlag_1F1EC_1F1FA,
  "U+1F1EC-U+1F1FC": CountryFlag_1F1EC_1F1FC,
  "U+1F1EC-U+1F1FE": CountryFlag_1F1EC_1F1FE,
  "U+1F1ED-U+1F1F0": CountryFlag_1F1ED_1F1F0,
  "U+1F1ED-U+1F1F2": CountryFlag_1F1ED_1F1F2,
  "U+1F1ED-U+1F1F3": CountryFlag_1F1ED_1F1F3,
  "U+1F1ED-U+1F1F7": CountryFlag_1F1ED_1F1F7,
  "U+1F1ED-U+1F1F9": CountryFlag_1F1ED_1F1F9,
  "U+1F1ED-U+1F1FA": CountryFlag_1F1ED_1F1FA,
  "U+1F1EE-U+1F1E8": CountryFlag_1F1EE_1F1E8,
  "U+1F1EE-U+1F1E9": CountryFlag_1F1EE_1F1E9,
  "U+1F1EE-U+1F1EA": CountryFlag_1F1EE_1F1EA,
  "U+1F1EE-U+1F1F1": CountryFlag_1F1EE_1F1F1,
  "U+1F1EE-U+1F1F2": CountryFlag_1F1EE_1F1F2,
  "U+1F1EE-U+1F1F3": CountryFlag_1F1EE_1F1F3,
  "U+1F1EE-U+1F1F4": CountryFlag_1F1EE_1F1F4,
  "U+1F1EE-U+1F1F6": CountryFlag_1F1EE_1F1F6,
  "U+1F1EE-U+1F1F7": CountryFlag_1F1EE_1F1F7,
  "U+1F1EE-U+1F1F8": CountryFlag_1F1EE_1F1F8,
  "U+1F1EE-U+1F1F9": CountryFlag_1F1EE_1F1F9,
  "U+1F1EF-U+1F1EA": CountryFlag_1F1EF_1F1EA,
  "U+1F1EF-U+1F1F2": CountryFlag_1F1EF_1F1F2,
  "U+1F1EF-U+1F1F4": CountryFlag_1F1EF_1F1F4,
  "U+1F1EF-U+1F1F5": CountryFlag_1F1EF_1F1F5,
  "U+1F1F0-U+1F1EA": CountryFlag_1F1F0_1F1EA,
  "U+1F1F0-U+1F1EC": CountryFlag_1F1F0_1F1EC,
  "U+1F1F0-U+1F1ED": CountryFlag_1F1F0_1F1ED,
  "U+1F1F0-U+1F1EE": CountryFlag_1F1F0_1F1EE,
  "U+1F1F0-U+1F1F2": CountryFlag_1F1F0_1F1F2,
  "U+1F1F0-U+1F1F3": CountryFlag_1F1F0_1F1F3,
  "U+1F1F0-U+1F1F5": CountryFlag_1F1F0_1F1F5,
  "U+1F1F0-U+1F1F7": CountryFlag_1F1F0_1F1F7,
  "U+1F1F0-U+1F1FC": CountryFlag_1F1F0_1F1FC,
  "U+1F1F0-U+1F1FE": CountryFlag_1F1F0_1F1FE,
  "U+1F1F0-U+1F1FF": CountryFlag_1F1F0_1F1FF,
  "U+1F1F1-U+1F1E6": CountryFlag_1F1F1_1F1E6,
  "U+1F1F1-U+1F1E7": CountryFlag_1F1F1_1F1E7,
  "U+1F1F1-U+1F1E8": CountryFlag_1F1F1_1F1E8,
  "U+1F1F1-U+1F1EE": CountryFlag_1F1F1_1F1EE,
  "U+1F1F1-U+1F1F0": CountryFlag_1F1F1_1F1F0,
  "U+1F1F1-U+1F1F7": CountryFlag_1F1F1_1F1F7,
  "U+1F1F1-U+1F1F8": CountryFlag_1F1F1_1F1F8,
  "U+1F1F1-U+1F1F9": CountryFlag_1F1F1_1F1F9,
  "U+1F1F1-U+1F1FA": CountryFlag_1F1F1_1F1FA,
  "U+1F1F1-U+1F1FB": CountryFlag_1F1F1_1F1FB,
  "U+1F1F1-U+1F1FE": CountryFlag_1F1F1_1F1FE,
  "U+1F1F2-U+1F1E6": CountryFlag_1F1F2_1F1E6,
  "U+1F1F2-U+1F1E8": CountryFlag_1F1F2_1F1E8,
  "U+1F1F2-U+1F1E9": CountryFlag_1F1F2_1F1E9,
  "U+1F1F2-U+1F1EA": CountryFlag_1F1F2_1F1EA,
  "U+1F1F2-U+1F1EB": CountryFlag_1F1F2_1F1EB,
  "U+1F1F2-U+1F1EC": CountryFlag_1F1F2_1F1EC,
  "U+1F1F2-U+1F1ED": CountryFlag_1F1F2_1F1ED,
  "U+1F1F2-U+1F1F0": CountryFlag_1F1F2_1F1F0,
  "U+1F1F2-U+1F1F1": CountryFlag_1F1F2_1F1F1,
  "U+1F1F2-U+1F1F2": CountryFlag_1F1F2_1F1F2,
  "U+1F1F2-U+1F1F3": CountryFlag_1F1F2_1F1F3,
  "U+1F1F2-U+1F1F4": CountryFlag_1F1F2_1F1F4,
  "U+1F1F2-U+1F1F5": CountryFlag_1F1F2_1F1F5,
  "U+1F1F2-U+1F1F6": CountryFlag_1F1F2_1F1F6,
  "U+1F1F2-U+1F1F7": CountryFlag_1F1F2_1F1F7,
  "U+1F1F2-U+1F1F8": CountryFlag_1F1F2_1F1F8,
  "U+1F1F2-U+1F1F9": CountryFlag_1F1F2_1F1F9,
  "U+1F1F2-U+1F1FA": CountryFlag_1F1F2_1F1FA,
  "U+1F1F2-U+1F1FB": CountryFlag_1F1F2_1F1FB,
  "U+1F1F2-U+1F1FC": CountryFlag_1F1F2_1F1FC,
  "U+1F1F2-U+1F1FD": CountryFlag_1F1F2_1F1FD,
  "U+1F1F2-U+1F1FE": CountryFlag_1F1F2_1F1FE,
  "U+1F1F2-U+1F1FF": CountryFlag_1F1F2_1F1FF,
  "U+1F1F3-U+1F1E6": CountryFlag_1F1F3_1F1E6,
  "U+1F1F3-U+1F1E8": CountryFlag_1F1F3_1F1E8,
  "U+1F1F3-U+1F1EA": CountryFlag_1F1F3_1F1EA,
  "U+1F1F3-U+1F1EB": CountryFlag_1F1F3_1F1EB,
  "U+1F1F3-U+1F1EC": CountryFlag_1F1F3_1F1EC,
  "U+1F1F3-U+1F1EE": CountryFlag_1F1F3_1F1EE,
  "U+1F1F3-U+1F1F1": CountryFlag_1F1F3_1F1F1,
  "U+1F1F3-U+1F1F4": CountryFlag_1F1F3_1F1F4,
  "U+1F1F3-U+1F1F5": CountryFlag_1F1F3_1F1F5,
  "U+1F1F3-U+1F1F7": CountryFlag_1F1F3_1F1F7,
  "U+1F1F3-U+1F1FA": CountryFlag_1F1F3_1F1FA,
  "U+1F1F3-U+1F1FF": CountryFlag_1F1F3_1F1FF,
  "U+1F1F4-U+1F1F2": CountryFlag_1F1F4_1F1F2,
  "U+1F1F5-U+1F1E6": CountryFlag_1F1F5_1F1E6,
  "U+1F1F5-U+1F1EA": CountryFlag_1F1F5_1F1EA,
  "U+1F1F5-U+1F1EB": CountryFlag_1F1F5_1F1EB,
  "U+1F1F5-U+1F1EC": CountryFlag_1F1F5_1F1EC,
  "U+1F1F5-U+1F1ED": CountryFlag_1F1F5_1F1ED,
  "U+1F1F5-U+1F1F0": CountryFlag_1F1F5_1F1F0,
  "U+1F1F5-U+1F1F1": CountryFlag_1F1F5_1F1F1,
  "U+1F1F5-U+1F1F2": CountryFlag_1F1F5_1F1F2,
  "U+1F1F5-U+1F1F3": CountryFlag_1F1F5_1F1F3,
  "U+1F1F5-U+1F1F7": CountryFlag_1F1F5_1F1F7,
  "U+1F1F5-U+1F1F8": CountryFlag_1F1F5_1F1F8,
  "U+1F1F5-U+1F1F9": CountryFlag_1F1F5_1F1F9,
  "U+1F1F5-U+1F1FC": CountryFlag_1F1F5_1F1FC,
  "U+1F1F5-U+1F1FE": CountryFlag_1F1F5_1F1FE,
  "U+1F1F6-U+1F1E6": CountryFlag_1F1F6_1F1E6,
  "U+1F1F7-U+1F1EA": CountryFlag_1F1F7_1F1EA,
  "U+1F1F7-U+1F1F4": CountryFlag_1F1F7_1F1F4,
  "U+1F1F7-U+1F1F8": CountryFlag_1F1F7_1F1F8,
  "U+1F1F7-U+1F1FA": CountryFlag_1F1F7_1F1FA,
  "U+1F1F7-U+1F1FC": CountryFlag_1F1F7_1F1FC,
  "U+1F1F8-U+1F1E6": CountryFlag_1F1F8_1F1E6,
  "U+1F1F8-U+1F1E7": CountryFlag_1F1F8_1F1E7,
  "U+1F1F8-U+1F1E8": CountryFlag_1F1F8_1F1E8,
  "U+1F1F8-U+1F1E9": CountryFlag_1F1F8_1F1E9,
  "U+1F1F8-U+1F1EA": CountryFlag_1F1F8_1F1EA,
  "U+1F1F8-U+1F1EC": CountryFlag_1F1F8_1F1EC,
  "U+1F1F8-U+1F1ED": CountryFlag_1F1F8_1F1ED,
  "U+1F1F8-U+1F1EE": CountryFlag_1F1F8_1F1EE,
  "U+1F1F8-U+1F1EF": CountryFlag_1F1F8_1F1EF,
  "U+1F1F8-U+1F1F0": CountryFlag_1F1F8_1F1F0,
  "U+1F1F8-U+1F1F1": CountryFlag_1F1F8_1F1F1,
  "U+1F1F8-U+1F1F2": CountryFlag_1F1F8_1F1F2,
  "U+1F1F8-U+1F1F3": CountryFlag_1F1F8_1F1F3,
  "U+1F1F8-U+1F1F4": CountryFlag_1F1F8_1F1F4,
  "U+1F1F8-U+1F1F7": CountryFlag_1F1F8_1F1F7,
  "U+1F1F8-U+1F1F8": CountryFlag_1F1F8_1F1F8,
  "U+1F1F8-U+1F1F9": CountryFlag_1F1F8_1F1F9,
  "U+1F1F8-U+1F1FB": CountryFlag_1F1F8_1F1FB,
  "U+1F1F8-U+1F1FD": CountryFlag_1F1F8_1F1FD,
  "U+1F1F8-U+1F1FE": CountryFlag_1F1F8_1F1FE,
  "U+1F1F8-U+1F1FF": CountryFlag_1F1F8_1F1FF,
  "U+1F1F9-U+1F1E6": CountryFlag_1F1F9_1F1E6,
  "U+1F1F9-U+1F1E8": CountryFlag_1F1F9_1F1E8,
  "U+1F1F9-U+1F1E9": CountryFlag_1F1F9_1F1E9,
  "U+1F1F9-U+1F1EB": CountryFlag_1F1F9_1F1EB,
  "U+1F1F9-U+1F1EC": CountryFlag_1F1F9_1F1EC,
  "U+1F1F9-U+1F1ED": CountryFlag_1F1F9_1F1ED,
  "U+1F1F9-U+1F1EF": CountryFlag_1F1F9_1F1EF,
  "U+1F1F9-U+1F1F0": CountryFlag_1F1F9_1F1F0,
  "U+1F1F9-U+1F1F1": CountryFlag_1F1F9_1F1F1,
  "U+1F1F9-U+1F1F2": CountryFlag_1F1F9_1F1F2,
  "U+1F1F9-U+1F1F3": CountryFlag_1F1F9_1F1F3,
  "U+1F1F9-U+1F1F4": CountryFlag_1F1F9_1F1F4,
  "U+1F1F9-U+1F1F7": CountryFlag_1F1F9_1F1F7,
  "U+1F1F9-U+1F1F9": CountryFlag_1F1F9_1F1F9,
  "U+1F1F9-U+1F1FB": CountryFlag_1F1F9_1F1FB,
  "U+1F1F9-U+1F1FC": CountryFlag_1F1F9_1F1FC,
  "U+1F1F9-U+1F1FF": CountryFlag_1F1F9_1F1FF,
  "U+1F1FA-U+1F1E6": CountryFlag_1F1FA_1F1E6,
  "U+1F1FA-U+1F1EC": CountryFlag_1F1FA_1F1EC,
  "U+1F1FA-U+1F1F3": CountryFlag_1F1FA_1F1F3,
  "U+1F1FA-U+1F1F8": CountryFlag_1F1FA_1F1F8,
  "U+1F1FA-U+1F1FE": CountryFlag_1F1FA_1F1FE,
  "U+1F1FA-U+1F1FF": CountryFlag_1F1FA_1F1FF,
  "U+1F1FB-U+1F1E6": CountryFlag_1F1FB_1F1E6,
  "U+1F1FB-U+1F1E8": CountryFlag_1F1FB_1F1E8,
  "U+1F1FB-U+1F1EA": CountryFlag_1F1FB_1F1EA,
  "U+1F1FB-U+1F1EC": CountryFlag_1F1FB_1F1EC,
  "U+1F1FB-U+1F1EE": CountryFlag_1F1FB_1F1EE,
  "U+1F1FB-U+1F1F3": CountryFlag_1F1FB_1F1F3,
  "U+1F1FB-U+1F1FA": CountryFlag_1F1FB_1F1FA,
  "U+1F1FC-U+1F1EB": CountryFlag_1F1FC_1F1EB,
  "U+1F1FC-U+1F1F8": CountryFlag_1F1FC_1F1F8,
  "U+1F1FD-U+1F1F0": CountryFlag_1F1FD_1F1F0,
  "U+1F1FE-U+1F1EA": CountryFlag_1F1FE_1F1EA,
  "U+1F1FE-U+1F1F9": CountryFlag_1F1FE_1F1F9,
  "U+1F1FF-U+1F1E6": CountryFlag_1F1FF_1F1E6,
  "U+1F1FF-U+1F1F2": CountryFlag_1F1FF_1F1F2,
  "U+1F1FF-U+1F1FC": CountryFlag_1F1FF_1F1FC,
  "U+1F3F3-U+FE0F-U+200D-U+1F308": CountryFlag_1F3F3_FE0F_200D_1F308,
  "U+1F3F4-U+200D-U+2620-U+FE0F": CountryFlag_1F3F4_200D_2620_FE0F,
  "U+1F3F4-U+E0067-U+E0062-U+E0065-U+E006E-U+E0067-U+E007F":
    CountryFlag_1F3F4_E0067_E0062_E0065_E006E_E0067_E007F,
  "U+1F3F4-U+E0067-U+E0062-U+E0073-U+E0063-U+E0074-U+E007F":
    CountryFlag_1F3F4_E0067_E0062_E0073_E0063_E0074_E007F,
  "U+1F3F4-U+E0067-U+E0062-U+E0077-U+E006C-U+E0073-U+E007F":
    CountryFlag_1F3F4_E0067_E0062_E0077_E006C_E0073_E007F,
}

export {
  AirplayIcon,
  ArrowDownAltIcon,
  ArrowDownIcon,
  ArrowLeftAltIcon,
  ArrowLeftIcon,
  ArrowRightAltIcon,
  ArrowRightIcon,
  ArrowSmallRightIcon,
  ArrowUpIcon,
  CastIcon,
  CheckIcon,
  CircleIcon,
  ContrastDarkIcon,
  ContrastLightIcon,
  ContrastIcon,
  Copy,
  CrossWrappedIcon,
  CrossIcon,
  DataIcon,
  DropDownIcon,
  HdOnIcon,
  InformationIcon,
  LoaderIcon,
  MailIcon,
  MoviePauseIcon,
  MoviePlayIcon,
  MovieSkipBackwardIcon,
  MovieSkipFowardIcon,
  MovieSkipNextIcon,
  MovieSkipPreviousIcon,
  OptionHorizontalIcon,
  OptionsVerticalIcon,
  PlusIcon,
  ReplayIcon,
  SaveIcon,
  SavedIcon,
  ScreenFullIcon,
  ScreenNormalIcon,
  SearchIcon,
  SettingsIcon,
  ShareIcon,
  ShuffleIcon,
  SoundOffIcon,
  SoundOnIcon,
  SquareOutlineIcon,
  SquareIcon,
  StarIcon,
  TrashIcon,
  AdditionalFootageIcon,
  BoardSizeIcon,
  BoardTypeIcon,
  BrandIcon,
  CalendarIcon,
  DirectedByIcon,
  DroneFootageIcon,
  EditIcon,
  FilmEditIcon,
  FilmEndIcon,
  FilmFootageIcon,
  FilmIcon,
  FinBonzerIcon,
  FinLessIcon,
  FinlessIcon,
  FinQuadIcon,
  FinSingleIcon,
  FinTriIcon,
  FinTwinIcon,
  FinIcon,
  FoamIcon,
  GenderFemaleIcon,
  GenderMaleIcon,
  LegendsSpecialAltIcon,
  LegendsSpecialIcon,
  LibraryIcon,
  LinkVideoIcon,
  LinkIcon,
  LocationIcon,
  MusicIcon,
  NarratedByIcon,
  OndemandMovieIcon,
  PresentedByIcon,
  ProducedByIcon,
  ProfileIcon,
  RailIcon,
  RuntimeIcon,
  SeriesIcon,
  ShaperIcon,
  ShopIcon,
  SponsoredAltIcon,
  SponsoredIcon,
  StanceGoofyIcon,
  StanceRegularIcon,
  SupportedIcon,
  SurfboardIcon,
  SurferAltIcon,
  SurferIcon,
  TailIcon,
  VolumeIcon,
  WaterFootageIcon,
  WaveBigIcon,
  WaveChestHeadIcon,
  WaveHugeIcon,
  WaveOverheadIcon,
  WaveSmallIcon,
  ArticleIcon,
  ArticleNewIcon,
  AndroidIcon,
  AppleIcon,
  FacebookIcon,
  GoogleIcon,
  InstagramIcon,
  TwitterIcon,
  TikTokIcon,
  NobodySurfIcon,
  YouTubeIcon,
  SpotifyIcon,
  PinterestIcon,
  VimeoIcon,
  SnapchatIcon,
  TeeIcon,
  CountryFlagMap,
}
