import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import FooterLinksBlock from "components/Footer/Links/Block"

const FooterLinks = (props) => (
  <div
    className={classNames(
      "grid grid-cols-2 gap-y-10",
      { "md:grid-cols-4": !props.compact },
      props.className,
    )}>
    {props.blocks.map((block) => (
      <FooterLinksBlock
        className="col-span-1"
        title={block.title}
        items={block.links}
        key={block.id + block.title}
        compact={props.compact}
      />
    ))}
  </div>
)

FooterLinks.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,

  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      links: PropTypes.array.isRequired,
    }).isRequired,
  ).isRequired,
}

export default FooterLinks
