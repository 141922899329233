import React from "react"
import PropTypes from "prop-types"

import FooterLinksListItem from "components/Footer/Links/ListItem"

const FooterLinksList = (props) => (
  <ul className={props.className}>
    {props.items.map((item) => (
      <FooterLinksListItem
        className="mt-1"
        href={
          props.compact ? (item.compactTo ? item.compactTo : item.to) : item.to
        }
        title={item.title}
        isInternal={item.isInternal}
        key={item.id}
      />
    ))}
  </ul>
)

FooterLinksList.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}

export default FooterLinksList
