import React from "react"
import PropTypes from "prop-types"

import FooterLinksList from "components/Footer/Links/List"

const FooterLinksBlock = (props) => (
  <div className={props.className}>
    <h3 className="fs-small-bold text-element-extra-emphasis-light">
      {props.title}
    </h3>
    <FooterLinksList
      className="mt-4"
      items={props.items}
      compact={props.compact}
    />
  </div>
)

FooterLinksBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array.isRequired,
  compact: PropTypes.bool,
}

export default FooterLinksBlock
