import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Divider = (props) => (
  <div
    className={classNames(
      props.className,
      "border-b border-element-divider-light",
    )}></div>
)

Divider.propTypes = {
  className: PropTypes.string,
}

export default Divider
