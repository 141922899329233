import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import OutsideClickHandler from "react-outside-click-handler"

import ModalMessage from "./Message"
import ModalAction from "./Action"

const Modal = (props) => {
  return (
    <div
      className={classNames(
        "flex items-end justify-center fixed inset-0 z-50",
        props.overlay && "bg-base-modal-overlay-light sm:items-center",
      )}>
      <OutsideClickHandler display="contents" onOutsideClick={props.onClose}>
        <div className="w-full sm:w-11/12 sm:px-4 md:max-w-md">
          <div
            className={
              props.theme === "dark"
                ? "bg-base-modal-dark text-element-extra-emphasis-dark"
                : "bg-base-modal-light text-element-extra-emphasis-light"
            }>
            {props.type === "message" && <ModalMessage {...props} />}
            {props.type === "action" && <ModalAction {...props} />}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  overlay: PropTypes.bool,
  theme: PropTypes.oneOf(["light", "dark"]),
  type: PropTypes.oneOf(["message", "action"]),
}

Modal.defaultProps = {
  type: "message",
}

export default Modal
