import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "components/UI/Button"
import { ArrowDownAltIcon, CrossIcon } from "assets/icons"

const ModalMessage = (props) => (
  <div
    className={classNames(
      "relative flex flex-col items-start w-full px-4 py-6 sm:px-6",
      props.className,
    )}>
    <div className="flex w-full">
      <div className="w-full">
        {props.title && (
          <h4 className="mt-1 mb-2 fs-large-bold">{props.title}</h4>
        )}
        <p className="whitespace-pre-wrap fs-base-paragraph-regular">
          {props.text}
        </p>
      </div>

      <CrossIcon
        className="flex-shrink-0 ml-4 cursor-pointer w-7 h-7"
        onClick={props.onClose}
      />
    </div>

    <Button
      className="w-full mt-4 md:mt-6 md:w-auto"
      label={props.buttonLabel}
      iconAfter={<ArrowDownAltIcon />}
      href={props.buttonHref}
      onClick={props.onClick}
      target="_blank"
      rel="noopener"
      size="large"
      variant="outlined"
      theme={props.theme}
    />
  </div>
)

ModalMessage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonHref: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  theme: PropTypes.oneOf(["light", "dark"]),
}

ModalMessage.defaultProps = {
  theme: "message",
}

export default ModalMessage
