import React from "react"
import PropTypes from "prop-types"

const ButtonContent = (props) => (
  <>
    {props.iconBefore && <div className="w-4 h-4 mr-1">{props.iconBefore}</div>}
    {props.label && <span>{props.label}</span>}
    {props.iconAfter && <div className="w-4 h-4 ml-1">{props.iconAfter}</div>}
  </>
)

ButtonContent.propTypes = {
  iconBefore: PropTypes.node,
  label: PropTypes.string,
  iconAfter: PropTypes.node,
}

export default ButtonContent
