import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"
import { useAmp } from "next/amp"
import Button from "components/UI/Button"
import { SearchIcon, DataIcon, CrossIcon, MoviePlayIcon } from "assets/icons"
import Logo from "assets/logo/logo+type.svg"
import Constants from "lib/constants"
import { event } from "lib/gtag"

const HeaderTopBar = (props) => {
  const iconStyles =
    "w-7 h-7 w-auto cursor-pointer text-element-extra-emphasis-light"
  const isAmp = useAmp()

  const handleClickSubscribe = () => {
    event({
      action: "Header",
      category: "Click",
      label: "Subscribe",
    })
  }

  const handleClickSearch = () => {
    event({
      action: "Header",
      category: "Click",
      label: "Search",
    })
  }

  return (
    <header
      className={classNames(
        props.className,
        "h-14 md:h-16 border-b border-element-divider-light bg-base-background-light",
      )}>
      <div className="flex items-center justify-between w-full h-full">
        <div className="flex items-center h-full px-4 py-2">
          {props.showSiteNav ? (
            <CrossIcon
              className={classNames(iconStyles, "hidden md:block")}
              onClick={props.toggleSiteNav}
            />
          ) : isAmp ? (
            <DataIcon
              tabIndex="-1"
              role="button"
              on="tap:sidebar.toggle"
              className={classNames(iconStyles, "hidden md:block")}
            />
          ) : (
            <DataIcon
              className={classNames(iconStyles, "hidden md:block")}
              onClick={props.toggleSiteNav}
            />
          )}

          <Link href="/search" prefetch={false}>
            <a
              className="md:ml-6"
              aria-label="Search"
              onClick={handleClickSearch}>
              <SearchIcon className={iconStyles} />
            </a>
          </Link>
        </div>

        <Link href="/" prefetch={false}>
          <a
            className="absolute transform -translate-x-1/2 left-1/2"
            aria-label="Top Page">
            <Logo className={classNames(iconStyles)} />
          </a>
        </Link>

        <div className="flex items-center h-full px-4 py-2">
          {props.showSiteNav ? (
            <CrossIcon
              className={classNames(iconStyles, "md:hidden")}
              onClick={props.toggleSiteNav}
            />
          ) : isAmp ? (
            <DataIcon
              tabIndex="-1"
              role="button"
              on="tap:sidebar.toggle"
              className={classNames(iconStyles, "md:hidden")}
            />
          ) : (
            <DataIcon
              className={classNames(iconStyles, "md:hidden")}
              onClick={props.toggleSiteNav}
            />
          )}
          <Button
            className="hidden md:flex"
            label="Download NobodySurf"
            iconBefore={<MoviePlayIcon />}
            variant="contained"
            theme="light"
            target="_blank"
            rel="noopener"
            href={Constants.Links.BadgeTopPage}
          />
        </div>
      </div>
    </header>
  )
}

HeaderTopBar.propTypes = {
  className: PropTypes.string,
  showSiteNav: PropTypes.bool,
  toggleSiteNav: PropTypes.func,
}

export default HeaderTopBar
